import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../imgs/media/Pre-comp 2_00074.png";
import Navbar from "./Navbar";
import Navigation from "./Navigation";

export class Header extends Component {
  render() {
    return (
      <>
        <div className="flex items-center pt-8 justify-between w-[90vw] mx-[5vw] z-10 Mobile:hidden">
          <div className="">
            <Link to="/">
              <div id="LogoHome">
                <img
                  id="white"
                  ref={this.logoRef}
                  src={Logo}
                  alt="logo"
                  className="w-16"
                />
              </div>
            </Link>
          </div>
          <div className="h-full w-[20rem] grid items-center">
            <Navigation />
          </div>
        </div>
        <div className=" Laptop:hidden PC:hidden Mobile:inline wide:hidden Xwide:hidden">
          <Navbar />
        </div>
      </>
    );
  }
}

export default Header;
