import React, { Component } from "react";
import Header from "../HeaderClients";
import { motion } from "framer-motion";
import ReactGA from "react-ga";

export class Page404 extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 1 } }}
      >
        <Header />
        <section className="h-[100vh] items-center grid justify-center">
          <h2 className="ph2">Page404</h2>
        </section>
      </motion.div>
    );
  }
}

export default Page404;
