import React from "react";
import HomePage from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUsPage";
import ContactUsPage from "./Pages/ContactUsPage";
import Page404 from "./Pages/Page404";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Work from "./Pages/Work";
import data from "../data.json";
import blog from "../blog.json";
import ClientsPage from "../components/Pages/ClientsPage";
import BlogPage from "./Pages/BlogPage/BlogPage";
import SingleBlog from "./Pages/BlogPage/SingleBlog/SingleBlog";

function AnimatedRoutes() {
	const location = useLocation();

	return (
		<AnimatePresence>
			<Routes
				location={location}
				key={location.pathname}
			>
				<Route
					path="/"
					element={<HomePage />}
				/>
				<Route
					path="/Work"
					element={<Work data={data} />}
				/>
				<Route
					path="/About-Us"
					element={<AboutUsPage />}
				/>
				<Route
					path="/Contact-Us"
					element={<ContactUsPage />}
				/>
				<Route
					path="/Blog"
					element={<BlogPage data={blog} />}
				/>
				<Route
					path="*"
					element={<Page404 />}
				/>
				{data.map((dat, index) => {
					return (
						<Route
							key={index}
							path={`/clients/${dat.link}`}
							element={<ClientsPage data={dat} />}
						/>
					);
				})}
				{blog.map((blg, index) => {
					return (
						<Route
							key={index}
							path={blg.url}
							element={<SingleBlog data={blg} />}
						/>
					);
				})}
			</Routes>
		</AnimatePresence>
	);
}

export default AnimatedRoutes;
