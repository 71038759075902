import React, { useEffect } from "react";
import {
	Color,
	Scene,
	Group,
	PerspectiveCamera,
	WebGLRenderer,
	Fog,
	Clock,
	AudioListener,
	Audio,
	// AudioLoader,
	MeshPhongMaterial,
	DoubleSide,
	Mesh,
	MeshBasicMaterial,
	CustomBlending,
	AddEquation,
	OneMinusDstColorFactor,
	OneMinusSrcAlphaFactor,
	AmbientLight,
	SphereGeometry,
	MeshStandardMaterial,
	TorusGeometry,
	Vector3,
	Float32BufferAttribute,
	TorusKnotGeometry,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import Logo3D from "../../models/1.obj";
import * as CANNON from "cannon-es";
import gsap from "gsap";
// import spaceSound from "../../sound/Mountain Audio - Enchanting Mysterious Space.mp3";
import InstaImg from "../../imgs/media/inverted 7.png";
import FaceImg from "../../imgs/media/inverted 8.png";
import BeImg from "../../imgs/media/inverted 9.png";
import LinkedImg from "../../imgs/media/inverted 10.png";
import PinImg from "../../imgs/media/inverted 11.png";
import TikImg from "../../imgs/media/inverted 12.png";
import YouImg from "../../imgs/media/inverted 13.png";

function Scene1() {
	useEffect(() => {
		const canvas = document.getElementById("canvas1");
		// const blog = document.getElementById("BlogHome");
		const work = document.getElementById("WorkHome");
		const AboutUs = document.getElementById("AboutUsHome");
		const Contact = document.getElementById("ContactHome");
		const HomeTitle = document.getElementById("HomeTitle");
		const Insta = document.getElementById("Insta");
		const Facebook = document.getElementById("Facebook");
		const Behance = document.getElementById("Behance");
		const Linkedin = document.getElementById("Linkedin");
		const Pintrest = document.getElementById("Pintrest");
		const Tiktok = document.getElementById("Tiktok");
		const Youtube = document.getElementById("Youtube");
		// const MouseMorph = document.getElementById('MouseMorph');
		const MouseExplore = document.getElementById("MouseExplore");
		const MouseGen = document.getElementById("MouseGen");
		const TapExp = document.getElementById("TapExp");
		const TapGen = document.getElementById("TapGen");
		// const HomeLogo = document.getElementById("HomeLogo");
		// blog.style.color = "#ffffff";
		work.style.color = "#ffffff";
		AboutUs.style.color = "#ffffff";
		Contact.style.color = "#ffffff";

		const scene = new Scene();
		scene.background = new Color(0x000000);

		const sizes = {
			width: window.innerWidth,
			height: window.innerHeight,
		};

		window.addEventListener("resize", () => {
			// Update sizes
			sizes.width = window.innerWidth;
			sizes.height = window.innerHeight;
			// console.log(sizes.width);
			// Update camera1
			camera.aspect = sizes.width / sizes.height;
			camera.updateProjectionMatrix();

			// Update renderer1
			renderer.setSize(sizes.width, sizes.height);
			renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		});
		const cameraGroup = new Group();
		scene.add(cameraGroup);

		// Base camera
		const camera = new PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 1000);
		camera.position.z = 10;
		camera.lookAt(0, 0, 0);
		camera.focus = 1;
		cameraGroup.add(camera);

		const listener = new AudioListener();
		camera.add(listener);

		const sound = new Audio(listener);

		// const audioLoader = new AudioLoader();
		// audioLoader.load(spaceSound, function (buffer) {
		//   sound.setBuffer(buffer);
		//   sound.setLoop(true);
		//   sound.setVolume(0.05);
		//   sound.play();
		// });

		//Cursor
		const cursor = {};
		cursor.x = 0;
		cursor.y = 0;

		// Controls
		const controls = new OrbitControls(camera, canvas);
		controls.enableDamping = true;
		controls.enableZoom = false;
		controls.enablePan = false;
		controls.enableRotate = false;

		//Renderer
		const renderer = new WebGLRenderer({
			canvas: canvas,
		});
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

		/**
		 * Fog
		 */
		const fog = new Fog("#000", 7.5, 8.5);
		scene.fog = fog;

		// Shapes
		const geometry2 = createGeometryBox();
		const geometry3 = createGeometryTorus();

		const material = new MeshPhongMaterial({
			color: 0x2c2c34,
			// flatShading: true,
			wireframe: true,
			side: DoubleSide,
		});

		let boxMesh = new Mesh(geometry3, material);
		let boxMesh2 = new Mesh(geometry2, material);
		let boxMesh4 = new Mesh(geometry2, material);
		scene.add(boxMesh4);

		let MeshBasicMaterialMaterial = new MeshBasicMaterial({
			color: 0xeaefff,
		});
		MeshBasicMaterialMaterial.blending = CustomBlending;
		MeshBasicMaterialMaterial.blendEquation = AddEquation; //default
		MeshBasicMaterialMaterial.blendSrc = OneMinusDstColorFactor; //default
		MeshBasicMaterialMaterial.blendDst = OneMinusSrcAlphaFactor; //default
		let boxMesh3;
		const objLoader = new OBJLoader();
		objLoader.load(Logo3D, (obj) => {
			obj.scale.x = 0;
			obj.scale.y = 0;
			obj.scale.z = 0;
			obj.rotation.z = Math.PI;

			obj.traverse(function (child) {
				if (child instanceof Mesh) {
					// child.material = SubtractEquation;
					// child.material = MeshBasicMaterialMaterial;
					// child.material = blackMat;
				}
			});

			boxMesh3 = obj;

			scene.add(boxMesh3);
		});

		scene.add(boxMesh, boxMesh2, boxMesh3);

		// Ambient Light
		let LightAm = new AmbientLight(0xffffff, 2);

		scene.add(LightAm);

		/**
		 * Physics
		 */
		//Materials
		const defaultMaterial = new CANNON.Material("default");

		//World
		const world = new CANNON.World();
		world.gravity.set(0, -9.82, 0);
		world.broadphase = new CANNON.SAPBroadphase(world);

		//Objects
		const floorShape = new CANNON.Plane();
		const floorBody = new CANNON.Body();
		floorBody.mass = 0;
		floorBody.addShape(floorShape);
		floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(-1, 0, 0), Math.PI * 0.5);
		floorBody.material = defaultMaterial;
		world.addBody(floorBody);

		const objectsToUpdate = [];
		const createSphere = (radius, position) => {
			// Three.js mesh
			const sphereGeometry = new SphereGeometry(1, 72, 72);
			const sphereMaterial = new MeshStandardMaterial({
				metalness: 0.3,
				roughness: 0.4,
			});

			// Three.js mesh
			const mesh = new Mesh(sphereGeometry, sphereMaterial);
			mesh.castShadow = true;
			mesh.scale.set(radius, radius, radius);
			mesh.position.copy(position);
			scene.add(mesh);

			// Cannon.js body
			const shape = new CANNON.Sphere(radius);

			const body = new CANNON.Body({
				mass: 1,
				position: new CANNON.Vec3(0, -3, 0),
				shape: shape,
				material: defaultMaterial,
			});
			body.position.copy(position);
			world.addBody(body);
			// Save in objects to update
			objectsToUpdate.push({
				mesh: mesh,
				body: body,
			});
		};

		//Contact Materials
		const defaultContactMaterial = new CANNON.ContactMaterial(defaultMaterial, defaultMaterial, {
			friction: 0.1,
			restitution: 0.7,
		});
		world.addContactMaterial(defaultContactMaterial);
		world.defaultContactMaterial = defaultContactMaterial;

		boxMesh.scale.set(0, 0, 0);
		boxMesh2.scale.set(0, 0, 0);
		if (window.innerWidth > 990) {
			window.addEventListener("mousemove", (event) => {
				gsap.to(boxMesh4.scale, {
					x: 0,
					y: 0,
					z: 0,
					duration: 1,
					ease: "power2",
				});
				gsap.to(boxMesh.scale, {
					x: 1,
					y: 1,
					z: 1,
					duration: 1,
					ease: "power2",
				});
				gsap.to(boxMesh2.scale, {
					x: 1,
					y: 1,
					z: 1,
					duration: 1,
					ease: "power2",
				});
				gsap.to(boxMesh3.scale, {
					x: 0.01,
					y: 0.01,
					z: 0.01,
					duration: 1,
					ease: "power2",
				});
				if (cursor.x > 0.85 && cursor.x < 0.95) {
					HomeTitle.style.mixBlendMode = "difference";
				}
				if (cursor.x > 0.95) {
					scene.remove(boxMesh, boxMesh2);
					gsap.to(boxMesh3.scale, {
						x: 0.2,
						y: 0.2,
						z: 0.01,
						duration: 1,
						ease: "power2",
					});
					// blog.style.color = "#000000";
					work.style.color = "#000000";
					AboutUs.style.color = "#000000";
					Contact.style.color = "#000000";
					HomeTitle.style.color = "#000000";
					HomeTitle.style.mixBlendMode = "normal";
					Insta.src = InstaImg;
					Facebook.src = FaceImg;
					Behance.src = BeImg;
					Linkedin.src = LinkedImg;
					Pintrest.src = PinImg;
					Tiktok.src = TikImg;
					Youtube.src = YouImg;
					// MouseMorph.style.display = 'none';
					MouseExplore.style.display = "none";
					MouseGen.style.display = "flex";

					setTimeout(() => {
						scene.background = new Color(0xffffff);
						gsap.to(camera.position, {
							duration: 0.5,
							ease: "power2",
							x: 0,
							y: 10,
							z: 0,
						});
						scene.remove(boxMesh3);
					}, 1500);

					if (objectsToUpdate.length < 75) {
						createSphere(Math.random() * 0.5, {
							x: 0.5 * Math.random(),
							y: 0,
							z: 0.5 * Math.random(),
						});
					} else {
						for (const object of objectsToUpdate) {
							// Remove body
							world.removeBody(object.body);

							// Remove mesh
							scene.remove(object.mesh);
						}
						objectsToUpdate.length = 0;
					}

					// return;
				} else {
					cursor.x = event.clientX / sizes.width;
					cursor.y = event.clientY / sizes.height;
					boxMesh.morphTargetInfluences[0] = 0.01 + cursor.y;
					boxMesh2.morphTargetInfluences[0] = 0.01 + cursor.y;
					boxMesh.position.z = 0 + cursor.x * 20;
					boxMesh2.position.z = -9 + cursor.x * 20;
					boxMesh3.position.z = -16 + cursor.x * 20;
					boxMesh4.position.z = -16 + cursor.x * 20;
				}
			});
		} else {
			window.addEventListener("mousedown", (event) => {
				gsap.to(boxMesh4.scale, {
					x: 0,
					y: 0,
					z: 0,
					duration: 1,
					ease: "power2",
				});
				gsap.to(boxMesh.scale, {
					x: 1,
					y: 1,
					z: 1,
					duration: 1,
					ease: "power2",
				});
				gsap.to(boxMesh2.scale, {
					x: 1,
					y: 1,
					z: 1,
					duration: 1,
					ease: "power2",
				});
				gsap.to(boxMesh3.scale, {
					x: 0.01,
					y: 0.01,
					z: 0.01,
					duration: 1,
					ease: "power2",
				});
				scene.remove(boxMesh, boxMesh2);
				gsap.to(boxMesh3.scale, {
					x: 0.2,
					y: 0.2,
					z: 0.01,
					duration: 1,
					ease: "power2",
				});
				// blog.style.color = "#000000";
				work.style.color = "#000000";
				AboutUs.style.color = "#000000";
				Contact.style.color = "#000000";
				HomeTitle.style.color = "#000000";
				HomeTitle.style.mixBlendMode = "normal";
				Insta.src = InstaImg;
				Facebook.src = FaceImg;
				Behance.src = BeImg;
				Linkedin.src = LinkedImg;
				Pintrest.src = PinImg;
				Tiktok.src = TikImg;
				Youtube.src = YouImg;
				TapExp.style.display = "none";
				TapGen.style.display = "flex";
				// MouseMorph.style.display = 'none';
				// MouseExplore.style.display = 'none';
				// MouseGen.style.display = 'none';

				setTimeout(() => {
					scene.background = new Color(0xffffff);
					gsap.to(camera.position, {
						duration: 0.1,
						ease: "power2",
						x: 0,
						y: 10,
						z: 0,
					});
					scene.remove(boxMesh3);
				}, 100);

				if (objectsToUpdate.length < 75) {
					createSphere(Math.random() * 0.5, {
						x: 0.5 * Math.random(),
						y: 0,
						z: 0.5 * Math.random(),
					});
				} else {
					for (const object of objectsToUpdate) {
						// Remove body
						world.removeBody(object.body);

						// Remove mesh
						scene.remove(object.mesh);
					}
					objectsToUpdate.length = 0;
				}
			});
		}

		/**
		 * Animate
		 */
		let oldElapsedTime = 0;
		const clock = new Clock();
		// let previousTime = 0;
		// let currentIntersect = null;
		const animate = () => {
			const elapsedTime = clock.getElapsedTime();
			// const delta = clock.getDelta();
			const deltaTime = elapsedTime - oldElapsedTime;
			oldElapsedTime = elapsedTime;
			world.step(1 / 60, deltaTime, 3);
			//   ballMesh.position.copy(sphereBody.position);
			for (const object of objectsToUpdate) {
				object.mesh.position.copy(object.body.position);
			}
			// particles.rotation.y += 0.0005;
			// for (var i = 0; i < smokeParticles.length; i++) {
			//   smokeParticles[i].rotation.z += delta * 0.2;
			// }
			// boxMesh.morphTargetInfluences[0] += 0.01;
			boxMesh.rotation.z -= 0.0015;
			boxMesh2.rotation.z -= 0.0015;
			boxMesh4.rotation.z -= 0.0015;
			// boxMesh.rotation.y -= 0.0015;

			// Animate camera
			// const parallaxX = cursor.x;
			// const parallaxY = -cursor.y;
			// holeMesh.position.x += (parallaxX - holeMesh.position.x) * 0.1;
			// holeMesh.position.y += (parallaxY - holeMesh.position.y) * 0.1;

			camera.lookAt(0, 0, 0);
			renderer.render(scene, camera);
			window.requestAnimationFrame(animate);
		};
		animate();

		AboutUs.addEventListener("click", () => {
			p11();
		});
		Contact.addEventListener("click", () => {
			p11();
		});
		function p11() {
			gsap.to(camera.position, {
				duration: 1,
				ease: "power2",
				x: "0",
				y: "1",
				z: "0",
			});
			setTimeout(() => {
				// sound.pause();
				gsap.to(camera.position, {
					duration: 1,
					ease: "power2",
					x: "0",
					y: "0",
					z: "0",
				});
			}, 1000);
			setTimeout(() => {
				sound.pause();
			}, 2000);
		}

		function createGeometryBox() {
			const geometry = new TorusGeometry(3, 2, 132, 132, Math.PI * 2);

			// create an empty array to  hold targets for the attribute we want to morph
			// morphing positions and normals is supported
			geometry.morphAttributes.position = [];

			// the original positions of the cube's vertices
			const positionAttribute = geometry.attributes.position;

			// for the first morph target we'll move the cube's vertices onto the surface of a sphere
			const spherePositions = [];

			// for the second morph target, we'll twist the cubes vertices
			const twistPositions = [];
			const direction = new Vector3(1, 0, 0);
			const vertex = new Vector3();

			for (let i = 0; i < positionAttribute.count; i++) {
				const x = positionAttribute.getX(i);
				const y = positionAttribute.getY(i);
				const z = positionAttribute.getZ(i);

				spherePositions.push(
					// 0,
					// 0,
					// 0
					x * Math.sqrt(1 - (y * y) / 2 - (z * z) / 2 + (y * y * z * z) / 3),
					y * Math.sqrt(1 - (z * z) / 2 - (x * x) / 2 + (z * z * x * x) / 3),
					z * Math.sqrt(1 - (x * x) / 2 - (y * y) / 2 + (x * x * y * y) / 3)
				);

				// stretch along the x-axis so we can see the twist better
				vertex.set(x * 2, y, z);

				vertex.applyAxisAngle(direction, (Math.PI * x) / 2).toArray(twistPositions, twistPositions.length);
			}

			// add the spherical positions as the first morph target
			geometry.morphAttributes.position[0] = new Float32BufferAttribute(spherePositions, 3);

			// add the twisted positions as the second morph target
			geometry.morphAttributes.position[1] = new Float32BufferAttribute(twistPositions, 3);

			return geometry;
		}

		function createGeometryTorus() {
			const geometry = new TorusKnotGeometry(2, 0.7, 132, 32);

			// create an empty array to  hold targets for the attribute we want to morph
			// morphing positions and normals is supported
			geometry.morphAttributes.position = [];

			// the original positions of the cube's vertices
			const positionAttribute = geometry.attributes.position;

			// for the first morph target we'll move the cube's vertices onto the surface of a sphere
			const spherePositions = [];

			// for the second morph target, we'll twist the cubes vertices
			const twistPositions = [];
			const direction = new Vector3(1, 0, 0);
			const vertex = new Vector3();

			for (let i = 0; i < positionAttribute.count; i++) {
				const x = positionAttribute.getX(i);
				const y = positionAttribute.getY(i);
				const z = positionAttribute.getZ(i);

				spherePositions.push(
					// 0,
					// 0,
					// 0
					x +
						Math.sqrt(
							4 + (Math.random() * x) / 2 - (Math.random() * x) / 2 + (Math.random() * x * Math.random() * x) / 3
						),
					y * Math.sqrt(1 - (z * z) / 2 - (x * x) / 2 + (z * z * x * x) / 3),
					z * Math.sqrt(1 - (x * x) / 2 - (y * y) / 2 + (x * x * y * y) / 3)
				);

				// stretch along the x-axis so we can see the twist better
				vertex.set(x * 2, y, z);

				vertex.applyAxisAngle(direction, (Math.PI * x) / 2).toArray(twistPositions, twistPositions.length);
			}

			// add the spherical positions as the first morph target
			geometry.morphAttributes.position[0] = new Float32BufferAttribute(spherePositions, 3);

			// add the twisted positions as the second morph target
			geometry.morphAttributes.position[1] = new Float32BufferAttribute(twistPositions, 3);

			return geometry;
		}
	}, []);

	return (
		<>
			<div>
				<canvas
					id="canvas1"
					className="webgl "
				/>
			</div>
		</>
	);
}

export default Scene1;
