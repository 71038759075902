import React, { useEffect } from "react";
import Vid from "../Vid";
import ImgGrid from "../ImgGrid";
import Header from "../HeaderClients";

function ClientsPage(props) {
	const data = props.data;
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
			/* you can also use 'auto' behaviour
		in place of 'smooth' */
		});
	});
	return (
		<>
			<Header />
			<div id="Client_Container">
				<section className="grid">
					<h2 className="ph2">{data.title}</h2>
					<div className="mouse_scroll">
						<div className="mouse">
							<div className="wheel"></div>
						</div>
						<div>
							<span className="m_scroll_arrows unu"></span>
							<span className="m_scroll_arrows doi"></span>
							<span className="m_scroll_arrows trei"></span>
						</div>
					</div>
				</section>
				{data.D3.status ? (
					<>
						<section className=" h-auto">
							<div className="mb-8">
								<h3>3D Design</h3>
								<hr className="w-[60vw]" />
							</div>
						</section>
						{data.D3.mimg.img ? (
							<section className="">
								<div className="grid mb-8 pr-2">
									<img
										src={`/assets/${data.D3.mimg.img}`}
										alt={data.D3.mimg.alt}
										className="pr-4 Mobile:px-4"
									/>
								</div>
							</section>
						) : (
							<></>
						)}

						{data.D3.vids.length > 0 ? (
							<>
								{data.title === "BTB Markets" ? (
									<section className=" grid gap-4 justify-center">
										{data.D3.vids.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								) : (
									<section className=" flex flex-row flex-wrap gap-4 justify-center Mobile:grid Mobile:grid-cols-1 Mobile:pr-0 Mobile:mb-4 Mobile:gap-4">
										{data.D3.vids.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								)}
							</>
						) : (
							<></>
						)}
						<p
							className=" font-NoirProLight text-[18px] mb-8"
							style={{ marginBottom: "2rem" }}
						>
							{data.D3.text}
						</p>
						<ImgGrid
							data={data.D3.imgs}
							limg={data.D3.limg}
						/>
						{data.D3.vids2.length > 0 ? (
							<>
								{data.title === "Booza" ? (
									<section className=" flex flex-row flex-wrap gap-4 justify-center Mobile:grid Mobile:grid-cols-1 Mobile:pr-0 Mobile:mb-4 Mobile:gap-4">
										{data.D3.vids2.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								) : (
									<section className=" grid gap-4 justify-center">
										{data.D3.vids2.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								)}
							</>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
				{data.UXUI.status ? (
					<>
						<section className=" h-auto">
							<div className="mb-8">
								<h3>UXUI</h3>
								<hr className="w-[60vw]" />
							</div>
						</section>
						{data.UXUI.mimg.img ? (
							<section className="">
								<div className="grid mb-8 pr-2">
									<img
										src={`/assets/${data.UXUI.mimg.img}`}
										alt={data.UXUI.mimg.alt}
										className="pr-4 Mobile:px-4"
									/>
								</div>
							</section>
						) : (
							<></>
						)}

						{data.UXUI.vids.length > 0 ? (
							<>
								{data.title === "BTB Markets" ? (
									<section className=" grid gap-4 justify-center">
										{data.UXUI.vids.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								) : (
									<section className=" flex flex-row flex-wrap gap-4 justify-center Mobile:grid-cols-1 Mobile:pr-0 Mobile:mb-4 Mobile:gap-4">
										{data.UXUI.vids.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								)}
							</>
						) : (
							<></>
						)}
						<p
							className=" font-NoirProLight text-[18px] mb-8"
							style={{ marginBottom: "2rem" }}
						>
							{data.UXUI.text}{" "}
						</p>
						<ImgGrid
							data={data.UXUI.imgs}
							limg={data.UXUI.limg}
						/>
						{data.UXUI.vids2.length > 0 ? (
							<>
								{data.title === "Booza" ? (
									<section className=" flex flex-row gap-4 justify-center">
										{data.UXUI.vids2.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								) : (
									<section className=" grid gap-4 justify-center">
										{data.UXUI.vids2.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								)}
							</>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
				{data.BR.status ? (
					<>
						<section className=" h-auto">
							<div className="mb-8">
								<h3>Branding</h3>
								<hr className="w-[60vw]" />
							</div>
						</section>
						{data.BR.mimg.img ? (
							<section className="">
								<div className="grid mb-8 pr-2">
									<img
										src={`/assets/${data.BR.mimg.img}`}
										alt={data.BR.mimg.alt}
										className="pr-4 Mobile:px-4"
									/>
								</div>
							</section>
						) : (
							<></>
						)}

						{data.BR.vids.length > 0 ? (
							<>
								{data.title === "BTB Markets" ? (
									<section className=" grid gap-4 justify-center">
										{data.BR.vids.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								) : (
									<section className=" flex flex-row flex-wrap gap-4 justify-center Mobile:grid-cols-1 Mobile:pr-0 Mobile:mb-4 Mobile:gap-4">
										{data.BR.vids.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								)}
							</>
						) : (
							<></>
						)}
						<p
							className=" font-NoirProLight text-[18px] mb-8"
							style={{ marginBottom: "2rem" }}
						>
							{data.BR.text}
						</p>
						<ImgGrid
							data={data.BR.imgs}
							limg={data.BR.limg}
						/>
						{data.BR.vids2.length > 0 ? (
							<>
								{data.title === "Booza" ? (
									<section className=" flex flex-row gap-4 justify-center">
										{data.BR.vids2.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								) : (
									<section className=" grid gap-4 justify-center">
										{data.BR.vids2.map((vid, index) => {
											return (
												<Vid
													key={index}
													data={vid}
												/>
											);
										})}
									</section>
								)}
							</>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
}

export default ClientsPage;
