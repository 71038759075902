import React, { useRef, useEffect } from "react";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";
import Mus from "../sound/Mountain Audio - Enchanting Mysterious Space.mp3";

function MuteButton() {
  const MOn = useRef(null);
  const MOff = useRef(null);
  useEffect(() => {
    MOff.current.className = "hidden";
  }, []);
  const audioRef = useRef(null);

  function playAudio() {
    audioRef.current.play();
    MOn.current.className = "hidden";
    MOff.current.className = "inline";
  }

  function pauseAudio() {
    audioRef.current.pause();
    MOn.current.className = "inline";
    MOff.current.className = "hidden";
  }

  return (
    <div className="bottom-12 left-0 fixed pl-[10vw] Mobile:hidden mix-blend-difference">
      <audio ref={audioRef} src={Mus} loop />
      <button ref={MOn} onClick={playAudio}>
        <MusicOffIcon />
      </button>
      <button ref={MOff} onClick={pauseAudio}>
        <MusicNoteIcon />
      </button>
    </div>
  );
}

export default MuteButton;
