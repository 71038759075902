import React, { Component } from 'react';
import Header from '../Header';
import SceneContactUs from '../Scenes/SceneContactUs';
import { motion } from 'framer-motion';
import ScrollButton from '../ScrollButton';
import { storage } from '../../firebase';
import { ref, uploadBytes } from 'firebase/storage';
import ReactGA from 'react-ga';
// import MuteButton from "../MuteButton";

export class ContactUsPage extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  constructor(props) {
    super(props);
    this.nameRef = React.createRef();
    this.phoneRef = React.createRef();
    this.CVURef = React.createRef();
    this.emailRef = React.createRef();

    this.state = {
      fullName: '',
      email: '',
      phone: '',
      CV: null,
      message: '',
    };
  }

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
    // console.log([input]);
  };

  stepValidation = () => {
    let isValid = true;
    if (this.state.fullName === '') {
      this.nameRef.current.className = 'animate-[bounce_0.7s_ease-in-out_2]';
      isValid = false;
    } else {
      this.nameRef.current.className = '';
    }
    if (this.state.phone === '') {
      this.phoneRef.current.className = 'animate-[bounce_0.7s_ease-in-out_2] ';
      isValid = false;
    } else {
      this.phoneRef.current.className = '';
    }
    if (this.state.email === '') {
      this.emailRef.current.className = 'animate-[bounce_0.7s_ease-in-out_2] ';
      isValid = false;
    } else {
      this.emailRef.current.className = '';
    }
    if (this.state.CV === null) {
      this.CVURef.current.className = 'animate-[bounce_0.7s_ease-in-out_2] ';
      isValid = false;
    }
    return isValid;
  };
  upload = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        'https://v1.nocodeapi.com/fadidev/google_sheets/HoYPPKcFFkVjMaBP?tabId=Sheet1',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([
            [
              this.state.fullName,
              this.state.phone,
              this.state.email,
              this.state.message,
            ],
          ]),
        }
      );
      await response.json();
    } catch (error) {
      console.log(error);
    }
  };
  submit = () => {
    alert('Thank you for your Submission.\nOur Team will contact you soon!');
    this.setState({
      fullName: '',
      phoneNumber: '',
      email: '',
      countryOfResidance: '',
    });
    this.nameRef.current.value = '';
    this.phoneRef.current.value = '';
    this.emailRef.current.value = '';
    // console.log(this.state);
  };

  done = (e) => {
    e.preventDefault();
    let isValid = this.stepValidation();
    if (isValid) {
      this.upload(e);
      this.submit();
      this.uploadCV();
    }
  };

  uploadCV = () => {
    if (this.state.CV === null) return;

    const CVRef = ref(storage, `${this.state.fullName}/${this.state.CV.name}`);
    uploadBytes(CVRef, this.state.CV).then(() => {});
  };
  handleKeyPress = (e) => {
    var key = e.key;
    var regex = /^[0-9\b]+$/;
    if (regex.test(key) | (key === 'Backspace')) {
      // console.log("You pressed a key: " + key);
    } else {
      e.preventDefault();
    }
  };

  render() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
      >
        <SceneContactUs />
        <Header />
        <div className="grid justify-start w-full pb-4 px-[5rem] mt-20 Mobile:mt-[27rem] Mobile:px-[7vw] PC:px-[4vw] PC:h-[60vh] PC:grid PC:content-center wide:mt-4">
          <div className="border-b-2 border-[#272727] w-[92vw] Mobile:w-full">
            <h1 className="text-[100px] font-NoirPro text-left w-full Mobile:text-[40px] PC:text-[50px] Mobile:text-start Mobile:my-0 Mobile:-mt-16">
              Contact Us
            </h1>
          </div>
          <div className="grid grid-cols-2 gap-x-8 Mobile:grid-cols-1">
            <div
              className="border-r-2 border-[#272727] Mobile:border-r-0
            "
            >
              {/* Form */}
              <div className="grid pl-24 w-[30rem] content-between h-full Mobile:p-0 Mobile:w-full PC:p-0 PC:pr-20">
                <h3 className=" text-left font-NoirProLight text-[14px] mt-8 px-4 Mobile:px-0">
                  Looking for a job? Apply now for a chance to become a part of
                  our family
                </h3>
                <div className="w-full">
                  <div className="grid content-between">
                    <input
                      type="text"
                      ref={this.nameRef}
                      placeholder="Full Name*"
                      onChange={this.handleChange('fullName')}
                      defaultValue={this.state.fullName}
                    />
                    <input
                      type="text"
                      ref={this.emailRef}
                      placeholder="Email Address*"
                      onChange={this.handleChange('email')}
                      defaultValue={this.state.email}
                    />
                    <input
                      ref={this.phoneRef}
                      type="tel"
                      placeholder="Phone Number*"
                      onChange={this.handleChange('phone')}
                      onKeyDown={(e) => {
                        this.handleKeyPress(e);
                      }}
                      defaultValue={this.state.phone}
                    />
                    <div>
                      <label
                        ref={this.CVURef}
                        className="custom-file-upload"
                      >
                        Upload Your CV*
                        <input
                          id="file-upload"
                          type="file"
                          // accept=".pdf"
                          onChange={(event) => {
                            this.setState({
                              CV: event.target.files[0],
                            });
                          }}
                        />
                      </label>
                    </div>
                    <input
                      type="textarea"
                      placeholder="Message"
                      onChange={this.handleChange('message')}
                      defaultValue={this.state.message}
                    />
                  </div>
                  <button
                    id="sub"
                    onClick={this.done}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="pl-24 grid mt-8 mb-3 content-between Mobile:pl-0 Mobile:mb-20 Mobile:border-t-2 Mobile:border-[#272727] Mobile:pt-12 PC:pl-10">
              <div>
                <h4 className="text-start font-NoirPro text-[#ffffff] ">
                  Collaboration
                </h4>
                <p className="text-[14px] text-start font-NoirProLight mb-2">
                  If you have a potential project or collaboration, <br />
                  please contact us at:
                </p>
                <a
                  href="mailto:hello@stitchesstudios.com"
                  className="text-[#ffffff59] font-NoirProLight hover:text-white"
                >
                  hello@stitchesstudios.com
                </a>
              </div>
              <div>
                <h4 className="text-start mt-8 font-NoirPro text-[#ffffff] ">
                  Support
                </h4>
                <p className="text-[14px] text-start font-NoirProLight mb-2">
                  For general inquiries, please email us at:
                </p>
                <a
                  href="mailto:info@stitchesstudios.com"
                  className="text-[#ffffff59] font-NoirProLight hover:text-white"
                >
                  info@stitchesstudios.com
                </a>
                <p className="text-[14px] text-start font-NoirProLight mt-4 mb-2">
                  You can also contact us by phone:
                </p>

                <p className="text-[14px] text-start font-NoirProLight">
                  If you’re in Lebanon{' '}
                  <a
                    href="tel:+961 71 202 358"
                    className="text-[#ffffff59] font-NoirProLight hover:text-white"
                  >
                    +961 71 202 358
                  </a>
                </p>
                <p className="text-[14px] text-start font-NoirProLight">
                  If you’re in Kuwait{' '}
                  <a
                    href="tel:+96596987929"
                    className="text-[#ffffff59] font-NoirProLight hover:text-white"
                  >
                    +965 9698 7929
                  </a>
                </p>
              </div>
              <div>
                <h4 className="text-start mt-8 font-NoirPro text-[#ffffff] ">
                  Address
                </h4>
                <p className="text-[14px] text-[#ffffff59] text-start font-NoirProLight">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://goo.gl/maps/bEXcvxrq7Z4LSGTH6"
                    className="hover:text-white"
                  >
                    Lebanon, Dbayeh, Waterfront, BP1, Office 407
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Mobile:hidden">
          <ScrollButton />
        </div>
      </motion.div>
    );
  }
}
export default ContactUsPage;
