import { Masonry } from "@mui/lab";
import { Box } from "@mui/material";
import React from "react";

function ImgGrid(props) {
	const data = props.data;
	const limg = props.limg;
	return (
		<>
			<div className="mob">
				<Box
					sx={{
						minHeight: 829,
					}}
				>
					<Masonry
						columns={1}
						spacing={2}
					>
						{data.map((item, index) => (
							<div key={index}>
								<img
									src={`/assets/${item.img}`}
									alt={item.alt}
									loading="lazy"
									style={{
										display: "block",
										width: "100%",
									}}
								/>
							</div>
						))}
					</Masonry>
					{limg.img === "" ? (
						<></>
					) : (
						<img
							src={`/assets/${limg.img}`}
							alt={limg.alt}
							className="pr-4"
						/>
					)}
				</Box>
			</div>
			<div className="tabl">
				<Box
					sx={{
						minHeight: 829,
					}}
				>
					<Masonry
						columns={2}
						spacing={1}
					>
						{data.map((item, index) => (
							<div key={index}>
								<img
									src={`/assets/${item.img}`}
									alt={item.alt}
									loading="lazy"
									style={{
										display: "block",
										width: "100%",
									}}
								/>
							</div>
						))}
					</Masonry>
					<img
						src={`/assets/${limg.img}`}
						alt={limg.alt}
						className="pr-2"
					/>
				</Box>
			</div>
			<div className="ever">
				<Box
					sx={{
						minHeight: 829,
					}}
				>
					<Masonry
						columns={2}
						spacing={1}
					>
						{data.map((item, index) => (
							<div key={index}>
								<img
									src={`/assets/${item.img}`}
									alt={item.alt}
									loading="lazy"
									style={{
										display: "block",
										width: "100%",
									}}
								/>
							</div>
						))}
					</Masonry>
					<img
						src={`/assets/${limg.img}`}
						alt={limg.alt}
						className="pr-2"
					/>
				</Box>
			</div>
		</>
	);
}

export default ImgGrid;
