import React from "react";
import "./SingleBlog.scss";
import Header from "../../../HeaderClients";

function SingleBlog(props) {
	const data = props.data;
	return (
		<>
			<Header />
			<div id="single_blog_container">
				<h1>{data.title}</h1>
				<img
					src={`/assets/${data.img}`}
					alt={data.title}
				/>
				<p>
					{data.category} <br />
					{data.date}
				</p>
				<p id="content">{data.content}</p>
			</div>
		</>
	);
}

export default SingleBlog;
