import React, { useEffect } from 'react';
import {
  Scene,
  Color,
  WebGLRenderer,
  Group,
  PerspectiveCamera,
  DirectionalLight,
  PointLight,
  // BoxGeometry,
  Raycaster,
  Vector2,
  Vector3,
  Fog,
  Mesh,
  MeshLambertMaterial,
  // TorusKnotGeometry,
  DodecahedronGeometry,
} from 'three';
import gsap from 'gsap';

function SceneAboutUs() {
  useEffect(() => {
    // let INTERSECTED;
    // const pointer = new Vector2();
    let theta = 0;
    const radius = 200;
    const canvas = document.getElementById('canvasAU');

    const scene = new Scene();
    scene.background = new Color(0x000000);

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    //Renderer
    const renderer = new WebGLRenderer({
      canvas: canvas,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const cameraGroup = new Group();
    scene.add(cameraGroup);

    // Base camera
    const camera = new PerspectiveCamera(
      70,
      sizes.width / sizes.height,
      1,
      10000
    );
    camera.position.z = 1;
    camera.lookAt(0, 0, 0);
    camera.focus = 1;
    cameraGroup.add(camera);

    window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      // Update camera1
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      // Update renderer1
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    const light = new DirectionalLight(0xffffff, 1);
    light.position.set(1, 1, 1).normalize();
    scene.add(light);

    const ghost1 = new PointLight('#937DC2', 100, 150, 3);
    ghost1.castShadow = true;
    ghost1.shadow.mapSize.width = 256;
    ghost1.shadow.mapSize.height = 256;
    ghost1.shadow.camera.far = 7;
    scene.add(ghost1);

    const geometry = new DodecahedronGeometry(13, 0);
    // const raycaster = new Raycaster();

    const grp = new Group();
    for (let i = 0; i < 100; i++) {
      const object = new Mesh(
        geometry,
        new MeshLambertMaterial({ color: 0x181818 })
      );
      object.position.x = Math.random() * 600 - 300;
      object.position.y = Math.random() * 600 - 300;
      object.position.z = Math.random() * 600 - 300;
      object.rotation.x = Math.random() * 2 * Math.PI;
      object.rotation.y = Math.random() * 2 * Math.PI;
      object.rotation.z = Math.random() * 2 * Math.PI;
      // object.scale.x = Math.random() + 0.5;
      // object.scale.y = Math.random() + 0.5;
      // object.scale.z = Math.random() + 0.5;
      const rrr = Math.random();
      object.scale.set(rrr + 0.5, rrr + 0.5, rrr + 0.5);
      grp.add(object);
    }
    scene.add(grp);
    grp.scale.set(0, 0, 0);

    const raycaster = new Raycaster();

    /**
     * Mouse
     */
    const mouse = new Vector2();

    window.addEventListener('mousemove', (event) => {
      mouse.x = (event.clientX / sizes.width) * 2 - 1;
      mouse.y = -(event.clientY / sizes.height) * 2 + 1;
      ghost1.position.z = -mouse.x * 200;
      ghost1.position.y = mouse.y * 200;
      // console.log(mouse);
    });

    /**
     * Fog
     */
    const fog = new Fog('#000000', 1, 500);
    scene.fog = fog;
    /**
     * Animate
     */
    // const clock = new Clock();
    // let previousTime = 0;
    // let currentIntersect = null;
    const animate = () => {
      // const elapsedTime = clock.getElapsedTime();

      //Cast a Ray
      const rayOrigin = new Vector3(-3, 0, 0);
      const rayDirection = new Vector3(1, 0, 0);
      rayDirection.normalize();
      raycaster.set(rayOrigin, rayDirection);

      theta += 0.0005;
      camera.position.x = radius;
      grp.rotation.y = theta;
      camera.lookAt(0, 0, 0);
      // camera.updateMatrixWorld();

      //Camera1
      camera.lookAt(0, 0, 0);
      // const deltaTime = elapsedTime - previousTime;
      // previousTime = elapsedTime;
      // Render1
      renderer.render(scene, camera);

      // Call tick again on the next frame
      window.requestAnimationFrame(animate);
    };
    animate();
    function p0() {
      gsap.to(grp.scale, {
        duration: 1,
        x: '1',
        y: '1',
        z: '1',
      });
    }
    setTimeout(() => {
      p0();
    }, 100);
  }, []);
  return (
    <div>
      <canvas
        id="canvasAU"
        className="webgl"
      ></canvas>
    </div>
  );
}

export default SceneAboutUs;

// const AmbLight = new AmbientLight(0xffffff, 1);
// scene.add(AmbLight);

// const Geo = new TorusGeometry(15, 7, 80, 80);
// const Mat = new MeshBasicMaterial({ wireframe: true, color: 0x2c2c34 });
// const HG = new Mesh(Geo, Mat);
// HG.position.z = -3;
// scene.add(HG);
// /**
//  * Fog
//  */
// const fog = new Fog("#000000", 1, 19);
// scene.fog = fog;
