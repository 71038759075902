import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "../App.css";

const FilterButton = () => {
  const onClick0 = () => {
    window.scrollTo(0, 0);
  };
  const onClick1 = () => {
    window.scrollTo(0, 3650);
  };
  const onClick2 = () => {
    window.scrollTo(0, 18500);
  };
  const onClick3 = () => {
    window.scrollTo(0, 25800);
  };
  return (
    <div className="floating-container">
      <div className="floating-button">
        <FilterListIcon />
      </div>
      <div className="element-container">
        <button onClick={onClick0} className="float-element tooltip-left">
          <ArrowUpwardIcon />
        </button>
        <button onClick={onClick1} className="float-element tooltip-left">
          3D
        </button>
        <button onClick={onClick2} className="float-element tooltip-left">
          UXUI
        </button>
        <button onClick={onClick3} className="float-element tooltip-left">
          BR
        </button>
      </div>
    </div>
  );
};
export default FilterButton;
