import React, { useEffect } from "react";
import Header from "../HeaderClients";
import { motion } from "framer-motion";
import Card from "../CardV2";
import ScrollButton from "../ScrollButton";
import FilterButton from "../FilterButton";
import "../../App.css";
import ReactGA from "react-ga";

function Work(props) {
	const data = props.data;
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<motion.div
			initial={{ opacity: 1 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 0.5 } }}
		>
			<Header />
			{/* Featured */}
			<div className="grid mt-16 grid-cols-5 Mobile:hidden content-center border-b-2 border-[#272727] mr-[3.5vw] ml-[5vw] Mobile:mx-0">
				<div className="col-span-3 grid content-center justify-start">
					<h1 className="text-start font-NoirPro text-[100px] Mobile:text-[50px] PC:text-[100px] ">Featured</h1>
				</div>
				<div className="col-span-2 grid content-center justify-end ">
					<div className="">
						<p className="text-[30px] text-end  font-NoirPro text-white leading-[1]">50+ Projects</p>
					</div>
					<div>
						<hr className="h-[3px] w-[20vw] -mb-4 mt-4 bg-white" />
					</div>
				</div>
			</div>
			<div className="PC:hidden wide:hidden Tablet:hidden Mobile:inline justify-start Xwide:hidden">
				<p className="text-[50px] text-white font-NoirPro text-start justify-start w-full px-[7vw]">Featured</p>
				<p className="text-start border-b-2 border-[#272727] px-[7vw] pb-2">50+ PROJECTS</p>
			</div>
			<div className="grid grid-cols-4 Mobile:grid-cols-1">
				<div className="col-span-1 Mobile:hidden grid sticky top-0 h-[100vh] justify-start content-center pl-[5vw] border-r-2 border-[#151515]">
					<h2>Index</h2>

					<ul className="justify-start grid">
						<li>
							<a
								className="text-white text-[22px]"
								href="#3D_Design"
							>
								3D Design
							</a>
						</li>
						<li>
							<a
								className="text-white text-[22px]"
								href="#UXUI"
							>
								UXUI
							</a>
						</li>
						<li>
							<a
								className="text-white text-[22px]"
								href="#Branding"
							>
								Branding
							</a>
						</li>
					</ul>
				</div>
				<div className="col-span-3 Mobile:col-span-1 p-16 Mobile:p-4 PC:p-4 Mobile:-mt-8">
					<div className="grid grid-cols-2 Mobile:grid-cols-1 gap-x-20 PC:gap-4 Mobile:gap-x-0 Mobile:gap-0">
						{data.map((dat, index) => {
							if (dat.feature) {
								return (
									<Card
										key={index}
										year={dat.year}
										title={`${dat.title}`}
										approach={`${dat.approach}`}
										link={`${dat.link}`}
										product={`${dat.product}`}
										img={dat.cover}
									/>
								);
							} else {
								return (
									<div
										className="hidden"
										key={index}
									></div>
								);
							}
						})}
					</div>
				</div>
			</div>
			{/* 3D Design */}
			<div className="grid grid-cols-5 Mobile:hidden content-center border-b-2 border-[#272727] mr-[3.5vw] ml-[5vw]">
				<div className="col-span-3">
					<h1
						id="3D_Design"
						className="text-start text-[100px] font-NoirPro PC:text-[100px]"
					>
						3D Design
					</h1>
				</div>
				<div className="col-span-2 grid content-center justify-end ">
					<div>
						<p className="text-[30px] text-end font-NoirPro text-white leading-[1]">22 Projects</p>
					</div>
					<div>
						<hr className="h-[3px] w-[20vw] -mb-4 mt-4 bg-white" />
					</div>
				</div>
			</div>
			<div className="PC:hidden wide:hidden Tablet:hidden Mobile:inline Xwide:hidden">
				<p className="text-[50px] text-white font-NoirPro justify-Start text-start w-full px-[7vw] ">3D Design</p>
				<p className="text-start border-b-2 border-[#272727] px-[7vw] pb-2">22 PROJECTS</p>
			</div>
			<div className="grid grid-cols-4 Mobile:grid-cols-1 Mobile:-mt-8">
				<div className="col-span-1 Mobile:hidden grid sticky top-0 h-[100vh] justify-start content-center pl-[5vw] border-r-2 border-[#151515]">
					<h2 className="PC:text-[30px]">3D Design</h2>

					<ul className="justify-start grid">
						<li>
							<a
								className="text-white text-[22px]"
								href="#UXUI"
							>
								UXUI
							</a>
						</li>
						<li>
							<a
								className="text-white text-[22px]"
								href="#Branding"
							>
								Branding
							</a>
						</li>
					</ul>
				</div>
				<div className="col-span-3 Mobile:col-span-1 p-16 Mobile:p-4 PC:p-4">
					<div className="grid grid-cols-2 Mobile:grid-cols-1 gap-x-20 PC:gap-4">
						{data.map((dat, index) => {
							if (dat.D3.status) {
								return (
									<Card
										key={index}
										year={dat.year}
										title={`${dat.title}`}
										approach={`${dat.approach}`}
										link={`${dat.link}`}
										product={`${dat.product}`}
										img={dat.cover}
									/>
								);
							} else {
								return (
									<div
										className="hidden"
										key={index}
									></div>
								);
							}
						})}
					</div>
				</div>
			</div>
			{/* UXUI */}
			<div className="grid grid-cols-5 Mobile:hidden content-center border-b-2 border-[#272727] mr-[3.5vw] ml-[5vw]">
				<div className="col-span-3">
					<h1
						id="UXUI"
						className="text-start font-NoirPro text-[100px] "
					>
						UX UI
					</h1>
				</div>
				<div className="col-span-2 grid content-center justify-end ">
					<div>
						<p className="text-[30px] text-end font-NoirPro text-white leading-[1]">13 Projects</p>
					</div>
					<div>
						<hr className="h-[3px] w-[20vw] -mb-4 mt-4 bg-white" />
					</div>
				</div>
			</div>
			<div className="PC:hidden wide:hidden Tablet:hidden Mobile:inline px-[5vw] Xwide:hidden">
				<p className="text-[50px] text-start text-white font-NoirPro justify-center w-full px-[7vw]">UXUI</p>
				<p className="text-start border-b-2 border-[#272727] px-[7vw] pb-2">13 PROJECTS</p>
			</div>
			<div className="grid grid-cols-4 Mobile:grid-cols-1">
				<div className="col-span-1 Mobile:hidden grid sticky top-0 h-[100vh] justify-start content-center pl-[5vw] border-r-2 border-[#151515]">
					<h2 className="PC:text-[30px]">UXUI</h2>

					<ul className="justify-start grid">
						<li>
							<a
								className="text-white text-[22px]"
								href="#3D_Design"
							>
								3D Design
							</a>
						</li>
						<li>
							<a
								className="text-white text-[22px]"
								href="#Branding"
							>
								Branding
							</a>
						</li>
					</ul>
				</div>
				<div className="col-span-3 p-16 Mobile:p-4 PC:p-4">
					<div className="grid grid-cols-2 Mobile:grid-cols-1 gap-x-20 PC:gap-4">
						{data.map((dat, index) => {
							if (dat.UXUI.status) {
								return (
									<Card
										key={index}
										year={dat.year}
										title={`${dat.title}`}
										approach={`${dat.approach}`}
										link={`${dat.link}`}
										product={`${dat.product}`}
										img={dat.cover}
									/>
								);
							} else {
								return (
									<div
										className="hidden"
										key={index}
									></div>
								);
							}
						})}
					</div>
				</div>
			</div>
			{/* Branding */}
			<div className="grid grid-cols-5 Mobile:hidden content-center border-b-2 border-[#272727] mr-[3.5vw] ml-[5vw]">
				<div className="col-span-3">
					<h1
						id="Branding"
						className="text-start text-[100px] font-NoirPro PC:text-[100px] "
					>
						Branding
					</h1>
				</div>
				<div className="col-span-2 grid content-center justify-end ">
					<div>
						<p className="text-[30px] text-end font-NoirPro text-white leading-[1]">15 Projects</p>
					</div>
					<div>
						<hr className="h-[3px] w-[20vw] -mb-4 mt-4 bg-white" />
					</div>
				</div>
			</div>
			<div className="PC:hidden wide:hidden Tablet:hidden Mobile:inline Xwide:hidden px-[5vw]">
				<p className="text-[50px] px-[7vw] text-start text-white font-NoirPro justify-center w-full ">Branding</p>
				<p className="text-start border-b-2 border-[#272727] px-[7vw] pb-2">15 PROJECTS</p>
			</div>
			<div className="grid grid-cols-4 Mobile:grid-cols-1">
				<div className="col-span-1 grid Mobile:hidden sticky top-0 h-[100vh] justify-start content-center pl-[5vw] border-r-2 border-[#151515]">
					<h2 className="PC:text-[30px]">Branding</h2>

					<ul className="justify-start grid">
						<li>
							<a
								className="text-white text-[22px]"
								href="#3D_Design"
							>
								3D Design
							</a>
						</li>
						<li>
							<a
								className="text-white text-[22px]"
								href="#UXUI"
							>
								UXUI
							</a>
						</li>
					</ul>
				</div>
				<div className="col-span-3 Mobile:col-span-1 p-16 Mobile:p-4 PC:p-4">
					<div className="grid grid-cols-2 Mobile:grid-cols-1 gap-x-20 PC:gap-4">
						{data.map((dat, index) => {
							if (dat.BR.status) {
								return (
									<Card
										key={index}
										year={dat.year}
										title={`${dat.title}`}
										approach={`${dat.approach}`}
										link={`${dat.link}`}
										product={`${dat.product}`}
										img={dat.cover}
									/>
								);
							} else {
								return (
									<div
										className="hidden"
										key={index}
									></div>
								);
							}
						})}
					</div>
				</div>
			</div>
			<div className="Mobile:hidden PC:hidden wide:hidden">
				<ScrollButton />
				{/* <MuteButton /> */}
			</div>
			<div className="PC:hidden wide:hidden Tablet:hidden Xwide:hidden Mobile:inline justify-start sticky bottom-4">
				<FilterButton />
			</div>
		</motion.div>
	);
}

export default Work;
