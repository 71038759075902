import React, { useEffect } from 'react';
import Header from '../Header';
import SceneAboutUs from '../Scenes/SceneAboutUs';
import { motion } from 'framer-motion';
import Linkedin from '../../imgs/media/Group 10.png';
// import stock from "../../imgs/media/cropped-FAVICON-1.png";
// import PH1 from "../../videos/placeholder 1.mp4";
// import PH2 from "../../videos/placeholder 2.mp4";
// import Gif from '../../imgs/2.png';
// import Gif2 from '../../imgs/sexy.png';
// import Fadi from "../../imgs/avatars/fadi.png";
import ScrollButton from '../ScrollButton';
import ReactGA from 'react-ga';
import AboutUsCard from '../AboutUsCard';
// import MuteButton from "../MuteButton";

function AboutUsPage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
    >
      <SceneAboutUs />

      <Header />
      <div>
        <section className="px-[5vw] Mobile:mx-4">
          <div className="border-b-2 border-[#272727] w-full Mobile:-mt-20 Mobile:pb-4 ">
            <h2 className=" font-NoirPro text-[100px] Mobile:text-[50px]">
              About Us
            </h2>
          </div>
        </section>
        <section className="px-[5vw] Mobile:px-[5vw]">
          <div className="grid grid-cols-2 Mobile:grid-cols-1 Tablet:grid-cols-1 content-center">
            <div className="grid h-full content-start Mobile:order-2 Mobile:px-4">
              <h2 className="font-NoirPro justify-start flex Mobile:text-[35px]  ">
                How It All Started
              </h2>
              <p className="text-left text-[18px] grid h-full mt-4 font-NoirProLight Mobile:text-[16px]">
                It started off with our two main characters, working the regular
                9 to 5 jobs. Killing their productivity, and diminishing their
                creativity. Landing clients was a robotic process, they were
                associated with account numbers, and instead of focusing on
                their needs, the main focus was the overall bill and deadline.
                <br />
                <br />
                But wait for a second, this is not right, this is not how it’s
                supposed to be, at least in our hero's perspective, we could do
                more, we must break the cycle. It started off on a couch, in a
                house, with two cups of coffee, and a whole lot of ambitions,
                dreams, and hopes!
              </p>
            </div>
            <div className="flex justify-center">
              {/* <img alt="" src={Gif} /> */}
              {/* <video src={PH1} loop autoPlay /> */}
            </div>
          </div>
          <div className=" grid-cols-2 Mobile:grid-cols-1 Mobile:px-4 hidden">
            {/* <div className="flex justify-center pr-8">
              <img
                alt=""
                src={Gif2}
              />
              <video src={PH2} loop autoPlay />
            </div> */}
            <div>
              <h2 className="font-NoirPro justify-start flex Mobile:text-[35px] Mobile:mt-8">
                How Its Going
              </h2>
              <p className="text-left text-[18px] font-NoirProLight  Mobile:p-0 Mobile:text-[16px]">
                They decided to slap a wooden countertop on 4 wooden legs and
                create the first desk. Funny enough, the iconic couch was torn,
                and in order to fix it, it needed many stitches, and here is
                where they metaphorically associated the idea of stitching
                (fixing the couch) with stitches (combining forces to achieve
                the client's ultimate perception).
                <br />
                <br />
                They aimed at creating a Studio that connects with its clients
                on a personal side, inviting them to join the stitches family,
                these kinds of relationships helped bridge the gap between the
                clients’ needs, aspirations, and the final outcome of any given
                project. A few months have gone by and this family expanded from
                2 heroes to 8 teammates thriving for the same ambitions, goals,
                and objectives; introducing stitches to the world.
              </p>
            </div>
          </div>
        </section>

        <section className="justify-items-start grid px-[5vw] Mobile:mx-4">
          <h2 className="mb-4 mt-8 Mobile:text-[35px] font-NoirPro Mobile:mb-0 ">
            Our Team
          </h2>
          <div className="grid grid-cols-3 w-full gap-16 Mobile:grid-cols-1 Mobile:gap-x-0 Mobile:gap-y-4">
            {Cards.map((person) => {
              return (
                <div className="grid justify-items-center">
                  <AboutUsCard
                    FullName={person.FullName}
                    position={person.position}
                    link={person.link}
                    Linkedin={person.Linkedin}
                  />
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <div className="Mobile:hidden">
        <ScrollButton />
        {/* <MuteButton /> */}
      </div>
    </motion.div>
  );
}

const Cards = [
  {
    FullName: 'Tarek Mohammad',
    position: 'CEO',
    link: 'linkedin.com/in/tarek-mohamad-849398aa',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Mayssa Kaady',
    position: 'Deputy CEO',
    link: 'https://www.linkedin.com/in/mayssa-kaady-647556131',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Abd El Kaderi',
    position: 'Partner',
    link: 'linkedin.com/in/abdelrahman-elkaderi',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Vestale Saliba',
    position: 'CMO',
    link: 'linkedin.com/in/vestale-saliba',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Fadi Ajami',
    position: 'CTO',
    link: 'linkedin.com/in/fadi-ajami-615a1b1b8',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Thalia Harouny',
    position: 'Senior Designer',
    link: 'linkedin.com/in/thalia-el-harouny-8b7a5719a',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Joe Estephan',
    position: 'Marketing Coordinator',
    link: 'linkedin.com/in/joe-stephan-73369221a',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Majd Abou Fadel',
    position: '3D & Motion Designer',
    link: 'https://www.linkedin.com/in/majd-abou-fadel-37120a26b?trk=contact-info',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Joanna Abou Karam',
    position: 'Graphic Designer',
    link: 'https://www.linkedin.com/in/joanna-abou-karam-18791b229',
    Linkedin: Linkedin,
  },
  {
    FullName: 'Iki The Cat',
    position: 'Support System',
    link: '',
  },
];

export default AboutUsPage;
