import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCq8-7sMNDW9QQh-HtEoML4mh2RyLbX2ck",
  authDomain: "stitches-studios.firebaseapp.com",
  projectId: "stitches-studios",
  storageBucket: "stitches-studios.appspot.com",
  messagingSenderId: "413004061771",
  appId: "1:413004061771:web:78d7a333074c2c1eba81ce",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
