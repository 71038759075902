import React from "react";
import Header from "../../HeaderClients";
import "./BlogPage.scss";
import FeedCard from "./FeedCard/FeedCard";
import Related from "./Related Card/Related";

function BlogPage(props) {
	const data = props.data;
	return (
		<>
			<Header />
			<div id="blog_page_container">
				<h1 id="title">Stitches Blog</h1>
				<h2 id="sub_title">Where Digital Meets Human</h2>
				<div id="blogs_container">
					<div id="feed_container">
						{data.map((dat, index) => {
							return (
								<FeedCard
									key={index}
									data={dat}
								/>
							);
						})}
					</div>
					<div id="related">
						{data.map((dat, index) => {
							if (dat.related) {
								return (
									<Related
										key={index}
										data={dat}
									/>
								);
							} else {
								return <div key={index}></div>;
							}
						})}
					</div>
				</div>
			</div>
		</>
	);
}

export default BlogPage;
