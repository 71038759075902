import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
	return (
		<>
			<div className="flex justify-between Mobile:mx-4">
				<Link to="/Blog">
					{/* <button
						id="Work"
						className="font-[30px]"
					>
						Blog
					</button> */}
				</Link>
				<Link to="/Work">
					<button
						id="Work"
						className="font-[30px]"
					>
						Work
					</button>
				</Link>
				<Link
					to="/About-Us"
					className="font-[30px]"
				>
					<button id="AboutUs"> About Us</button>
				</Link>
				<Link
					to="/Contact-Us"
					className="font-[30px]"
				>
					<button id="Contact"> Contact Us</button>
				</Link>
			</div>
		</>
	);
}

export default Navigation;
