import React, { useEffect, useState } from 'react';
import Header from '../HeaderHome';
import Scene1 from '../Scenes/Scene1';
import { motion } from 'framer-motion';
import Instagram from '../../imgs/media/Group 7.png';
import Facebook from '../../imgs/media/Group 8.png';
import Behence from '../../imgs/media/Group 9.png';
import Linkedin from '../../imgs/media/Group 10.png';
import Pintrest from '../../imgs/media/Group 11.png';
import TikTok from '../../imgs/media/Group 12.png';
import Youtube from '../../imgs/media/Group 13.png';
import ReactGA from 'react-ga';
import mouse from '../../imgs/mouse/Asset 1.png';
import mouseb from '../../imgs/mouse/Asset 1black.png';

function HomePage() {
  const texts = ['To Explore', 'To Morph'];
  const [currentText, setCurrentText] = useState(texts[0]);
  const switchTextt = () => {
    setTimeout(() => {
      const currentIndex = texts.indexOf(currentText);
      const nextIndex = (currentIndex + 1) % texts.length;
      setCurrentText(texts[nextIndex]);
    }, 2000);
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    switchTextt();
  });
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
        className="grid justify-center"
      >
        <Scene1 />

        <Header />
        <section className="flex h-[65vh] items-center justify-center ">
          <div>
            <h1 id="HomeTitle">
              A World Where <br />
              Digital Meets Human
            </h1>
          </div>
          {/* <MuteButton /> */}
          <div className="flex fixed w-full justify-between px-[5vw] bottom-12 Mobile:w-full Mobile:px-[2rem] Mobile:grid Mobile:grid-cols-1">
            <div className="w-[33%] Mobile:hidden"></div>
            <div className="flex justify-center gap-16 w-[33%] Mobile:w-full">
              <div
                id="MouseExplore"
                className="text-white mix-blend-difference flex justify-center align-middle Mobile:hidden"
              >
                <img
                  id="MHor"
                  src={mouse}
                  alt="mouse"
                  className="w-4 aspect-auto mr-6"
                />
                {/* <span id="TExp">To Explore</span>
                <span id="TMor">To Morph</span> */}
                <span
                  className={
                    currentText === 'To Explore' ? 'visible' : 'hidden'
                  }
                >
                  To Explore
                </span>
                <span
                  className={currentText === 'To Morph' ? 'visible' : 'hidden'}
                >
                  To Morph
                </span>
              </div>
              <div
                id="MouseGen"
                className="text-black mix-blend-difference justify-center align-middle hidden Mobile:hidden"
              >
                <img
                  id="MGen"
                  src={mouseb}
                  alt="mouse"
                  className="w-4 aspect-auto mr-6"
                />
                To Generate
              </div>
              <div
                id="TapExp"
                className="Laptop:hidden wide:hidden PC:hidden Xwide:hidden Tablet:hidden mt-8"
              >
                <p className="text-white mix-blend-difference ">
                  Tap To Explore
                </p>
              </div>
              <div
                id="TapGen"
                className="hidden mt-8"
              >
                <p className="text-black mix-blend-difference ">
                  Tap To Generate
                </p>
              </div>
            </div>

            <div className="flex w-[33%] justify-end gap-4 Mobile:w-full Mobile:mt-4">
              {socials.map((obj) => {
                return (
                  <a
                    key={obj.id}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-1"
                    href={obj.link}
                  >
                    <img
                      id={obj.id}
                      className="w-[1.6rem]"
                      src={obj.image}
                      alt={obj.alt}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </section>
      </motion.div>
    </>
  );
}
const socials = [
  {
    link: 'https://www.instagram.com/stitches.studios/',
    image: Instagram,
    alt: 'Instagram',
    id: 'Insta',
  },
  {
    link: 'https://www.facebook.com/stitches.studios/',
    image: Facebook,
    alt: 'Facebook',
    id: 'Facebook',
  },
  {
    link: 'https://www.behance.net/stitchesstudios',
    image: Behence,
    alt: 'Behance',
    id: 'Behance',
  },
  {
    link: 'https://www.linkedin.com/company/stitches-studios/',
    image: Linkedin,
    alt: 'LinkedIn',
    id: 'Linkedin',
  },
  {
    link: 'https://www.pinterest.com/stitchesstudios/',
    image: Pintrest,
    alt: 'Pintrest',
    id: 'Pintrest',
  },
  {
    link: 'http://www.tiktok.com/@stitchesstudios',
    image: TikTok,
    alt: 'TikTok',
    id: 'Tiktok',
  },
  {
    link: 'https://www.youtube.com/channel/UCzSp0xu2k6HEJw_6us0pFFg',
    image: Youtube,
    alt: 'Youtube',
    id: 'Youtube',
  },
];

export default HomePage;
