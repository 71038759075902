import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../imgs/media/Pre-comp 2_00074.png";
import Navigation from "./Navigation";
import Navbar from "./Navbar";

export class Header extends Component {
	render() {
		return (
			<>
				<div className="flex justify-self-center items-center w-[90vw] pt-8 justify-between mx-[5vw] Mobile:hidden Tablet:hidden">
					<div className="">
						<Link to="/">
							<div id="LogoHome">
								<img
									id="white"
									ref={this.logoRef}
									src={Logo}
									alt="logo"
									className="w-16"
								/>
							</div>
						</Link>
					</div>
					<div className="h-full w-[20rem] ">
						<Navigation />
					</div>
				</div>
				<div className=" Laptop:hidden PC:hidden Mobile:inline wide:hidden">
					<Navbar />
				</div>
			</>
		);
	}
}

export default Header;
