import React from "react";
import { Link } from "react-router-dom";
import "./Related.scss";

function Related(props) {
	const data = props.data;
	return (
		<Link
			id="related_card_container"
			to={data.url}
		>
			<div id="col1">
				<img
					src={`/assets/${data.img}`}
					alt={data.title}
				/>
			</div>
			<div id="col2">
				<div id="row1">
					<p id="cat">{data.category}</p>
					<p id="dat">{data.date}</p>
				</div>
				<div id="row2">
					<h4>{data.title}</h4>
				</div>
			</div>
		</Link>
	);
}

export default Related;
