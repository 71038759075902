import React from 'react';

function AboutUsCard(props) {
  return (
    <div className="glass w-full justify-items-start grid p-4 Mobile:w-full Mobile:my-8">
      {/* <img alt="" src={stock} className="w-36 bg-white " /> */}
      {/* <div className="w-40 h-40 bg-white mb-4"></div> */}
      <h3 className=" text-[30px] text-left font-NoirProSemiBold Mobile:text-[25px]">
        {props.FullName}
      </h3>
      <h4 className=" text-[25px] text-left Mobile:text-[22px] font-NoirPro">
        {props.position}
      </h4>
      <a
        href={props.link}
        className="mt-4 rounded-full p-2"
      >
        <img
          src={props.Linkedin}
          alt=""
        />
      </a>
    </div>
  );
}

export default AboutUsCard;
