import "./App.css";
import React, { useEffect, useState } from "react";
import Favicon from "react-favicon";
import { BrowserRouter } from "react-router-dom";
import AnimatedRoutes from "./components/AnimatedRoutes";
import logo from "./imgs/media/fav.png";
import Loading from "../src/imgs/media/white-on-transparent-background.gif";
import ReactGA from "react-ga";
import MuteButton from "./components/MuteButton";

function App() {
	const Tracking_ID = "UA-175562937-2";
	ReactGA.initialize(Tracking_ID);

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 3000);
	}, []);
	return (
		<div>
			{loading ? (
				<div className="h-[100vh] w-[100vw] flex items-center justify-center">
					<img
						alt=""
						src={Loading}
						className="w-36 h-36 Mobile:w-20 Mobile:h-20"
					/>
				</div>
			) : (
				<>
					<Favicon url={logo}></Favicon>
					<BrowserRouter>
						<AnimatedRoutes />
					</BrowserRouter>
					<MuteButton />
				</>
			)}
		</div>
	);
}

export default App;
