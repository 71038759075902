import React, { useRef } from "react";
import { Link } from "react-router-dom";
import CallMadeIcon from "@mui/icons-material/CallMade";

function Card(props) {
	const box = useRef();
	const hr = useRef();
	const approach = useRef();
	const Y_P = useRef();
	const arrow = useRef();
	const mouseOver = () => {
		hr.current.className = "animate-w100 bg-white h-1 w-[90%] flex";
		approach.current.className = "justify-center font-NoirPro bg-[#151515] flex";
		Y_P.current.className = "hidden justify-between";
		arrow.current.className = "grid justify-center content-center animate-ping";
	};
	const mouseLeave = () => {
		hr.current.className = "animate-w0 bg-white h-1 w-0 hidden";
		approach.current.className = "justify-center font-NoirPro bg-[#151515] hidden";
		Y_P.current.className = "flex justify-between";
		arrow.current.className = "grid justify-center content-center";
	};

	return (
		<Link to={`/clients/${props.link}`}>
			<div
				className="my-20 Mobile:my-16 PC:my-4"
				ref={box}
				onMouseEnter={mouseOver}
				onMouseLeave={mouseLeave}
			>
				<div className="grid bg-[#151515] p-4 Mobile:justify-center Mobile:w-full">
					<div className="flex justify-between px-12 py-4 Mobile:px-4">
						<div className="w-full">
							<p className="text-left text-[24px]">{props.title}</p>
							<hr
								ref={hr}
								className="h-1 bg-white hidden"
								width="0%"
							/>
						</div>
						<div
							ref={arrow}
							className="grid justify-center content-center"
						>
							<CallMadeIcon
								sx={{
									fontSize: "40px",
									color: "#ffffff",
									"@media(max-Width: 480px)": {
										fontSize: "30px",
									},
								}}
							/>
						</div>
					</div>
					<div className="px-12 w-full flex justify-center my-5 Mobile:px-4">
						<img
							id="frontImg"
							className="w-[20rem] h-[20rem] Xwide:w-[500px] Xwide:h-[500px]"
							src={`/assets/${props.img}`}
							alt={`${props.title}`}
						/>
					</div>
					<div className="px-12 py-4 text-white font-NoirPro Mobile:px-6">
						<div
							ref={Y_P}
							className="flex justify-between"
						>
							<div>/{props.year}</div>
							<div>/{props.product}</div>
						</div>
						<div
							ref={approach}
							className="justify-center font-NoirPro bg-[#151515] hidden"
						>
							<div>{props.approach}</div>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
}

export default Card;
