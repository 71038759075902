import React from "react";

function Vid(props) {
	const data = props.data;
	return (
		<div>
			<div className="desktop">
				<iframe
					title="test"
					width={data.W_D}
					height={data.H_D}
					src={data.link}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</div>
			<div className="tabl">
				<iframe
					title="test"
					width={data.W_T}
					height={data.H_T}
					src={data.link}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</div>
			<div className="mob">
				<iframe
					title="test"
					style={{
						width: "100%",
						height: "100%",
						minWidth: data.W_M,
						minHeight: data.H_M,
					}}
					src={data.link}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</div>
		</div>
	);
}

export default Vid;
