import React, { useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import logo from "../imgs/media/white-on-transparent-background.gif";
import MuteButtonMob from "./MuteButtonMob";

const Navbar = () => {
	// to change burger classes
	const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
	const [menu_class, setMenuClass] = useState("menu hidden");
	const [isMenuClicked, setIsMenuClicked] = useState(false);

	// toggle burger menu change
	const updateMenu = () => {
		if (!isMenuClicked) {
			setBurgerClass("burger-bar clicked");
			setMenuClass("menu visible");
		} else {
			setBurgerClass("burger-bar unclicked");
			setMenuClass("menu hidden");
		}
		setIsMenuClicked(!isMenuClicked);
	};

	return (
		<div
			id="bg"
			className="p-4 sticky top-0 Xwide:hidden z-10"
		>
			<nav className="w-full justify-between items-center flex">
				<div>
					<Link to="/">
						<img
							src={logo}
							alt="Host & Ghost"
							className="w-10"
						/>
					</Link>
				</div>
				<div
					className="burger-menu"
					onClick={updateMenu}
				>
					<div className={burger_class}></div>
					<div className={burger_class}></div>
					<div className={burger_class}></div>
				</div>
			</nav>

			<div className={menu_class}>
				<div className="grid grid-cols-1 grid-rows-4 gap-y-8">
					{/* <div className="flex justify-center font-NoirPro text-white">
						<Link to={"/Blog"}>Blog</Link>
					</div> */}
					<div className="flex justify-center font-NoirPro text-white">
						<Link to={"/Work"}>Work</Link>
					</div>
					<div className="flex justify-center font-NoirPro text-white">
						<Link to={"/About-Us"}>About Us</Link>
					</div>
					<div className="flex justify-center font-NoirPro text-white">
						<Link to={"/Contact-US"}>Contact Us</Link>
					</div>
					<div className="flex justify-center font-NoirPro text-white">
						<MuteButtonMob />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
