import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../imgs/media/Pre-comp 2_00074.png";
import BlackLogo from "../imgs/media/black-on-transparent-background-+-reverse.gif";
import Navbar from "./Navbar";
// import Navigation from "./Navigation";

export class Header extends Component {
	constructor(props) {
		super(props);

		this.logoRef = React.createRef();
		this.logoRefB = React.createRef();
		this.state = {};
	}
	componentDidMount() {
		// const blog = document.getElementById("BlogHome");
		// blog.style.color = "#ffffff";
		const work = document.getElementById("WorkHome");
		work.style.color = "#ffffff";
		const AboutUs = document.getElementById("AboutUsHome");
		AboutUs.style.color = "#ffffff";
		const ContactUs = document.getElementById("ContactHome");
		ContactUs.style.color = "#ffffff";
		const sizes = {
			width: window.innerWidth,
			height: window.innerHeight,
		};
		const cursor = {};
		cursor.x = 0;
		cursor.y = 0;
		window.addEventListener("mousemove", (event) => {
			if (cursor.x < 0.95) {
				cursor.x = event.clientX / sizes.width;
				cursor.y = event.clientY / sizes.height;
			} else {
				this.logoRef.current.className = "w-16 hidden";
				this.logoRefB.current.className = "w-16 flex";
				return;
			}
		});
	}
	render() {
		return (
			<>
				<div className="flex items-center pt-8 justify-between w-[90vw] mx-[5vw] z-10 Mobile:hidden">
					<div className="">
						<Link to="/">
							<div id="LogoHome">
								<img
									id="white"
									ref={this.logoRef}
									src={Logo}
									alt="logo"
									className="w-16"
								/>
								<img
									ref={this.logoRefB}
									src={BlackLogo}
									alt="logo"
									className="w-16 hidden"
								/>
							</div>
						</Link>
					</div>
					<div className="h-full w-[20rem] grid items-center Mobile:w-[100%] Mobile:px-4 ">
						<div className="flex justify-between">
							{/* <Link to="/Blog">
								<button
									id="BlogHome"
									className="font-[30px] font-NoirPro"
								>
									Blog
								</button>
							</Link> */}
							<Link to="/Work">
								<button
									id="WorkHome"
									className="font-[30px]"
								>
									Work
								</button>
							</Link>
							<Link
								to="/About-Us"
								className="font-[30px] "
							>
								<button id="AboutUsHome"> About Us</button>
							</Link>
							<Link
								to="/Contact-Us"
								className="font-[30px]"
							>
								<button id="ContactHome"> Contact Us</button>
							</Link>
						</div>
					</div>
				</div>
				<div className=" Laptop:hidden PC:hidden Mobile:inline wide:hidden">
					<Navbar />
				</div>
			</>
		);
	}
}

export default Header;
